import './form'
import './popup'
import './animation'
import './ui-elements'

$(document).ready(function () {


		Fancybox.bind("[data-fancybox]", {});
		console.log(Fancybox);


		
		$('.js-nav-btn').on('click',function(){	
			var $this = $(this);
			var navBlock = $('.js-nav-block');
			$this.toggleClass('burger_active');
		
			$('header .menu-wrap').toggleClass('active'); 
		
		});


		var swiperTrusted = new Swiper(".js-reviews-slider", {
			loop: false,
			noSwiping: false,
			spaceBetween: 20,
			watchSlidesVisibility: true,
			slidesPerView: 2,
			pagination: {
				el: ".js-swiper-trusted .swiper-dots",
			},
			breakpoints: {
				992: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
				768: {
					slidesPerView: 2,
					noSwiping: false,
					noSwiping: true,
				},
				600: {
					slidesPerView: 1,
					noSwiping: false,
					noSwiping: true,
				},
			},
			navigation: {
				nextEl: '.js-news-slider__next',
				prevEl: '.js-news-slider__prev',
			},
		});
  
		var swiperTrusted = new Swiper(".js-service-slider", {
			loop: false,
			noSwiping: false,
			spaceBetween: 20,
			watchSlidesVisibility: true,
			slidesPerView: 2,
			pagination: {
				el: ".js-swiper-trusted .swiper-dots",
			},
			breakpoints: {
				992: {
					slidesPerView: 4,
					noSwiping: false,
					noSwiping: true,
				},
				768: {
					slidesPerView: 3,
					noSwiping: false,
					noSwiping: true,
				},
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

    $(window).scroll(function(){
      var navblock= $('header ');      
      if($(this).scrollTop()>0){
        navblock.addClass('fixed');
      } else{
        navblock.removeClass('fixed');
      }
    });

    $('.menu-btn').click(function () {
      $(this).find('.hamburger').toggleClass('is-active')
      if ($(this).find('.hamburger').hasClass('is-active')) {
        $('.menu-wrap').animate({"left":"0px"});
      } else {
        $('.menu-wrap').animate({"left":"-100%"});
      }  
    });

    $('.js-more-works').click(function(e){
      e.preventDefault();
      $('.work-items .hidden').slideDown();
      $(this).hide();
    });

    $('.menu-item-has-children').click(function(e){
        // e.preventDefault();
        if( ($(window).width()<992 )){
          if(e.target.tagName!="A") {	                  
            $(this).find('ul.sub-menu').slideToggle();
          }          
        }
    });

    $('.faq-item .faq-item__header').click(function(event){
        event.preventDefault();
        $(this).next().slideToggle();
        $(this).toggleClass('is-collapsed');
    });

    $('.service-item__more .service-item__more-title').click(function(event){
      event.preventDefault();
      $(this).next().slideToggle();
      $(this).toggleClass('is-collapsed');
  	});

		$('.js-category-item').on("click",function(e){

			e.preventDefault();
	
			$('.js-category-item').removeClass("active");
	
			$(this).addClass("active");
	
			let data={
				'term_id': $(this).data('term_id'), 
				'action': 'portfolio_filter',
				'count': $(this).data('count'),
			};
			console.log(data); 
	
			$.ajax({
				type: 'POST',
				url: '/wp-admin/admin-ajax.php',
				data: data, 
				error: function (jqXHR, exception) {
				},
				beforeSend : function ( xhr ) {		
				
				},
				success : function( data ){   
					console.log(data);
					if (data) {
						jQuery('.js-product-result').html(data);        
					} else { }
				}
			});
	
		});













		

});